export const appHead = {"meta":[{"name":"viewport","content":"height=device-height, width=device-width, initial-scale=1.0, minimum-scale=1.0, maximum-scale=5.0 viewport-fit=cover"},{"charset":"utf-8"}],"link":[],"style":[],"script":[{"id":"mess-google-maps-script","src":"https://maps.googleapis.com/maps/api/js?key=AIzaSyBb3U9fofIbEWHnNnbaAYKM0Au1FzE9yi8&libraries=places&loading=async","defer":true}],"noscript":[],"htmlAttrs":{"lang":"en"},"viewport":"height=device-height, width=device-width, initial-scale=1.0, minimum-scale=1.0, maximum-scale=5.0 viewport-fit=cover"}

export const appApp = {}

export const appBaseURL = "/"

export const appBuildAssetsDir = "/_nuxt/"

export const appCdnURL = ""

export const appLayoutTransition = false

export const appPageTransition = false

export const appViewTransition = false

export const appKeepalive = false

export const appRootId = "__nuxt"

export const appRootTag = "div"

export const appRootAttrs = {"id":"__nuxt"}

export const appTeleportTag = "div"

export const appTeleportId = "teleports"

export const appTeleportAttrs = {"id":"teleports"}

export const renderJsonPayloads = true

export const componentIslands = true

export const payloadExtraction = true

export const cookieStore = true

export const appManifest = true

export const remoteComponentIslands = undefined

export const selectiveClient = true

export const devPagesDir = null

export const devRootDir = null

export const devLogs = false

export const nuxtLinkDefaults = {"componentName":"NuxtLink","prefetch":true,"prefetchOn":{"visibility":true}}

export const asyncDataDefaults = {"value":null,"errorValue":null,"deep":true}

export const resetAsyncDataToUndefined = true

export const nuxtDefaultErrorValue = null

export const fetchDefaults = {}

export const vueAppRootContainer = '#__nuxt'

export const viewTransition = false

export const appId = "nuxt-app"

export const outdatedBuildInterval = 3600000

export const multiApp = false